import React from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
 
class Banner extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <>
                <div id="home" className="uk-banner uk-dark main-banner item-bg3">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="uk-container">
                                <div className="main-banner-content">
                                <h1 style={{textAlign: "center"}}>WELCOME TO </h1>
                               
                                    <h2 style={{textAlign: "center", color:"#15B5B0"}}><b><i>RnD IT Solutions Limited</i></b></h2><br />
                                    <h4>" Your One-Stop IT Solution Company In Bangladesh "</h4>
                                    <p><i>We are an Experienced IT Solutions and Software Development Company that always aims to provide the latest and Next Generation Software and Web Development Solutions.</i></p>
                                    <Link to="about" className="uk-button uk-button-default">More Info</Link>
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="video-btn popup-youtube">
                                            <span uk-icon="play"></span> Watch Video
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo 
                    channel='youtube' 
                    isOpen={this.state.isOpen} 
                    videoId='Oa9aWdcCC4o' 
                    onClose={() => this.setState({isOpen: false})} 
                />
            </>
        );
    }
}
 
export default Banner;