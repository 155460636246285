import React from 'react'
 
class AboutContent extends React.Component {
    render(){
        return (
            <section className="uk-about about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>About Us</span>
                                    <h2>Brief Background</h2>
                                    <div className="bar"></div>
                                </div>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    <h3>About RnD IT Solutions Limited</h3>
                                    <h4>RnD IT Solutions Limited is a Software Development Company that provides a range of IT Solutions amongst which is Enterprise Software development, Integrated Web Services which include web and intranet development and web hosting. We also provide Artificial Intelligence Technology Solutions, Healthcare Technology Solutions. Our approach to development is that of excellence, relevance, accessibility and usability and we have the necessary experience and expertise to build the kind of solution that will suit your company’s needs.</h4>
                                    
                                    

                                    <div className="signature">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="about-img">
                                <img src={require("../../assets/images/about5.jpg")} className="about-img1" alt="about-img" />
                                <img src={require("../../assets/images/about7-01.png")} className="shape-img" alt="shape" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-container">
                        <div className="item">
                            <div className="about-content">
                <div className="uk-section-title section-title">
                <h4>We are continuously seeking ways to provide our clients with best solution current cutting edge technology has to offer while at the same time ensuring that our solution provide our clients a usable product capable of realising a return on the investment made in developing their solution.</h4>
                        <h4>We distinguish ourselves from the rest as we have a single minded focus on development (unlike reselling other third party developed solutions) that communicate the business objectives simply while our user oriented functionality is designed to satisfy both the client and user expectations. We do not aim to dazzle you with our technical expertise but seek to help you state your business case in the best possible manner.
We are continuously seeking ways to provide our clients with best solution current cutting edge technology has to offer while at the same time ensuring that our solution provide our clients a usable product capable of realising a return on the investment made in developing their solution. We distinguish ourselves from the rest as we have a single minded focus on development (unlike reselling other third party developed solutions) that communicate the business objectives simply while our user oriented functionality is designed to satisfy both the client and user expectations. We do not aim to dazzle you with our technical expertise but seek to help you state your business case in the best possible manner.</h4>
                                    <br/>
                                    <h3><b>Brief Background</b></h3>
                                    <h4>Our purpose as an organisation is to provide solutions that make Information Technology relevant, readily accessible and applicable using cost effective and creative methods suitable to the business environment in which our clients and stakeholders operate.</h4>
                                   
                                    <br/>
                                   
                                    <h3><b>WHY WORK WITH US?</b></h3>

                                    <h4>We are capable and fully equipped to provide solutions for all relevant features and have previous experience with all services listed. We specialise in providing solution to clients with complex needs that require extensive programming as well as highly intuitive user interfaces. We are highly competent in analysing information, and this enables us to build service oriented services based on sound information architecture. Art direction We have proven in-house graphic designers whose ability has been fully demonstrated on New IT Development Languages and various ERP software’s.
These designers understand the importance of branding as well as the need to project a consistent visual identity. Writing We also have fully qualified writers holding qualifications in journalism and mass communication. Their professional abilities are fully demonstrated on all the websites we worked on, including instances where we had to help the client generate content from scratch. These two areas form our greatest strength since RnD IT Solutions Limited is largely a software development company and this strength, naturally, extends to web development. We have highly qualified developers, all of whom hold at least have ample work experience of 20 years. This is another area of strength as we already offer a dedicated hosting service from which several reputable companies are running their websites.
We have highly competent staff that are more than comfortable with a variety of server platforms and have experience in managing users, databases and servers. Flexibility as a matter of policy we will be at the disposal of your company 24/7 during the website design phase, however, this changes to on call during the maintenance phase. We will establish and maintain open channels of communication but will however retain email, fax and typed/handwritten documents as documents of record. WhatsApp conversations will only count in the interim but these should be followed up by written documents in order to ensure accountability. Creativity If creativity is confidence, then for us it would be confidence in our expertise and experience but we know there is more to it. We understand the value of Programming, Database design Systems Administration & Web Hosting.
We have a visual appeal and have taken time to develop talent in the area of graphic design and programming, with special emphasis being placed on enhancing user experience. In addition to visual appeal, our creative sphere extends to the enhancement of site functionality with special focus on accessibility, visibility, relevance and usability.</h4>
                                   <br/>
                                    <h3><b>Our Portfolio of RnD IT Solutions Limited:</b></h3>
                                    <h4>RnD IT Solutions Limited has on more than one occasion worked on websites with large sets of requirements. These large scale projects requiring a custom made Content Management System and world class design, these include:</h4>
                                    
                                    
                                    <br/>
                                    
                                    
                                    <h5><a href="https://rndworldwidesolutions.com">1. RnD Healthcare and Tourism Solutions Limited (Our Medical Tourism Company)</a></h5>
                                    
                                    <h5><a href="https://rndtelehealth.com">2. RnD Telehealth (Our Telemedicine Web Based Portal Software)</a></h5>
                                    
                                    <h5><a href="https://rndproductsbd.com">3. RnD Products Limited (Our E-Commerce Website, Online Market Place)</a></h5>
                                    
                                    <h5><a href="https://rndtourismsolutions.com">4. RnD Tourism Solutions (Our Online Based Travel Agency with Hotel and Car Booking Facilities)</a></h5>
                                    
                                    <h5><a href="https://rnditsolutions.com">5. RnD IT Solutions Limited (Our Marketing Website for RnD IT)</a></h5>  
                                    
                                    <h5><a href="https://rndagrolimited.com">6. RnD Agro Limited (Our Agriculture and Farming Marketing Website)</a></h5>
                                    
                                    <h5><a href="https://islamictheologybd.com">7. Islamic Theology BD – Authentic Islamic Blog (Our Online based Video Blogging Website)</a></h5>
                                    
                                   
                                   
                                   
                                    <br/>
                                   


                                    <h4>All these websites are being maintained by people with high level of web technology experience and have constant access to the site at all times through content management software. These systems are built on robust Microsoft platforms which provides ease of deployment and scalability allowing end user system administrators to easily manage and use the system on a daily basis.</h4>
                                    
                            
                                    <br/>
                                    <h4>Asset Management System with bar code scanning – RnD IT Solutions Limited has also developed an Asset management system that suits local operating conditions and is capable of supporting large numbers of users without requiring large capital expenditure upfront as it can run no existing servers and hardware. Its main modules include bar code scanning, inventory/stock control, fleet management, asset management, and reporting modules.</h4>
                                    

                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    
            </section>
        );
    }
}
 
export default AboutContent;