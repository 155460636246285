import React from 'react';
import lax from 'lax.js';
import LaxButton from '../Common/LaxButton';
 
class About extends React.PureComponent {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <section id="about" className="uk-about  about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>OUR BUSINESS PROFILE</span>
                                    <h2>BUSINESS PROFILE</h2>
                                    <div className="bar"></div>
                                </div>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    <h3>Software Development</h3>
                                    <h4>Brief Description</h4>
                                    <h4>RnD IT Solutions Limited is a full cycle software engineering and development company that delivers quality software and development services using best practices in business process analysis and modelling and world-class development techniques. Exceeding expectations, understanding our clients' businesses and architecting robust high-quality solutions at surprisingly affordable costs are just a few of the key differentiators that set RnD IT Solutions Limited apart from other software development firms.</h4>
 
                                    
                                    <div className="signature">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="about-img">
                            <img src={require("../../assets/images/about5.jpg")} className="about-img1" alt="about-img" />
                                <img src={require("../../assets/images/about7-01.png")} className="shape-img" alt="shape" />

                                <LaxButton />
                                </div>
                        </div>
                    </div>
                                
                    <div className="uk-section-title section-title">
                        <h4>RnD IT Solutions Limited can provide clients with highly customized and diversified business applications and development solutions according to need. Our expertise in this area ranges from simple, peer to peer applications, to large and complex enterprise applications used by hundreds of people. Organizations are looking for ways to reduce costs and improve the accessibility of IT applications. Increasingly, it means moving away from traditional software development cycles. Either case, RnD IT Solutions Limited has a proven record to assist you throughout the process.</h4>
                                    <h5>◉ Asset Management Systems</h5>
                                    <h5>◉ ERP System</h5>
                                    <h5>◉ Fleet Management System</h5>
                                    <h5>◉ CRM Systems</h5>
                                    <h5>◉ Custom Systems to cater for different client needs</h5>
                                    <br/>
                                    <h3>Web Development Team of RnD IT Solutions Limited:</h3>
                                    <h4>Web development division prides itself in developing websites that reflect our clients’ quality standards and experience while making an impact on clients’ bottom line. We understand clients’ need to realise return on investment (ROI) and thus tailor made websites whose architecture is service and function oriented. This package includes hosting of the website which is of an advantage to your company as our hosting package comes with the following as standard:</h4>
                                    
                                    <h5>◉ Unlimited Databases (normal usage terms apply)</h5>
                                    <h5>◉ Unlimited Disk space - (normal usage terms apply)</h5>
                                    <h5>◉ Unlimited FTP accounts</h5>
                                    <h5>◉ Free Site Statics</h5>
                                    <h5>◉ Scripting - (PHP, MySQL)</h5>  
                                    <h5>◉ Live support via telephone, email reports</h5>
                                    <h5>◉ Unlimited Bandwidth</h5>
                                    <h5>◉ 99% Uptime Guarantee</h5>                               
                                    <br/>
                                    <h4>RnD IT Solutions Limited has found that there is a need for maintenance of our client’s website as most of our clients are very busy and don’t have the time to perform regularly updates and maintenance of their websites.</h4>
                                    <br/>
                                    <h4>As such, we will not only be developing and hosting your website but also will dedicate all our time and energy to ensure that your site stays up to date and relevant and keeps up to date with latest website trends. This management will include:</h4>
                              
                                    <h5>◉ General maintenance to the website</h5>
                                    <h5>◉ Adding new functions as and when required</h5>
                                    <h5>◉ Designing and Developing new pages as when and required</h5>
                                    <h5>◉ Take limited calls for photography sessions</h5>
                                    
                                    <br/>
                                    <h4>This package is developed to ensure that our clients spend less time worrying about their website and more time attending to important business matters yet still enjoying they Return on Investment coming from their well-managed website.</h4>
                                    
                                    <br/>

                                    <h3>I.T. Consultancy and Outsourcing</h3>
                                    <br/>
                                    <h4>Brief Description:</h4>
                                    <h4>Our focus is to keep your IT systems operational, available and secure so that you can focus on the everyday demands of expanding your business, managing costs and increasing revenues. We’re here to help you realize the productivity gains and return on investment (ROI) you have been expecting from your computer systems all along. System downtime, viruses, spyware, loss of productivity.... Are the computer systems you rely upon to run your business not working consistently and as expected? These distractions are unnecessary, time consuming and very expensive. IT Support of RnD IT Solutions Limited team dedicates all their time to make sure that every need off our clients have been met and if not we will ensure that we create a solution to your problems. There are often times when an organisation’s IT needs reach a level where it could benefit from having more resources in house to:</h4> 
                               
                                    <h5>◉ Supplement internal teams during peak periods</h5>
                                    <h5>◉ Cover for staff on long term absences</h5>
                                    <h5>◉ Provide technical skills that are not available within the organisation</h5>
                                    <h5>◉ Impart technical advice during key project stages</h5>
                                    <h5>◉ Undertake ancillary tasks to free up staff to focus on core activities</h5>
                               
                               </div>
                            </div>
            </section>
        );
    }
}
 
export default About;