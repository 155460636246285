import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';


class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            subject: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.subject = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    subjectChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.subject = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div className="alert-success" uk-alert-success="true">
                    <Link to="#" onClick={this.onHideSuccess} className="uk-alert-close" uk-close="true"></Link>
                    <h3>Thank you</h3>
                    <p>We will connect you soon.</p>
                </div>
            );
        }
    }
    render(){
        return (
            <section id="contact" className="contact-area uk-contact uk-section">
                <div className="uk-container">

<h4>Terms of Service (&quot;Terms&quot;)</h4>

<h5>Our Terms of Service were last updated on 5 th December 2022.</h5>
<h5>Please read these terms and conditions carefully before using Our Service.</h5>

<br></br>
<h4>Interpretation and Definitions</h4>
<h5>Interpretation</h5>
<h5>The words of which the initial letter is capitalized have meanings defined under the following
conditions. The following definitions shall have the same meaning regardless of whether they
appear in singular or in plural.</h5>

<br></br> 
<h4>Definitions</h4>
<h5>For the purposes of these Terms of Service:</h5>
<h5>● “Affiliate” means an entity that controls, is controlled by or is under common control with
a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest
or other securities entitled to vote for election of directors or other managing authority.</h5>
<h5>
● “Account” means a unique account created for You to access our Service or parts of
our Service.</h5>

<h5>● “Company” (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
refers to RnD IT Solutions Limited.</h5>

<h5>● “Country” refers to Bangladesh region.</h5>

<h5>● “Content” refers to content such as text, images, or other information that can be
posted, uploaded, linked to or otherwise made available by You, regardless of the form
of that content.</h5>

<h5>● “Device” means any device that can access the Service such as a computer, a cell
phone or a digital tablet.</h5>

<h5>● “Feedback” means feedback, innovations or suggestions sent by You regarding the
attributes, performance or features of our Service.</h5>

<h5>● “Service” refers to the Website.</h5>

<h5>● “Terms of Service” (also referred as &quot;Terms&quot;) mean these Terms of Service that form
the entire agreement between You and RnD IT Solutions Limited regarding the use of
the Service.</h5>

<h5>● “Third-party Social Media Service” means any services or content (including data,
information, products or services) provided by a third-party that may be displayed,
included or made available by the Service.</h5>

<h5>● “Website” refers to RnD IT Solutions Limited website, accessible from
https://www.rnditsolutions.com/
</h5>
<h5>● “You” means the individual accessing or using the Service, or the company, or other
legal entity on behalf of which such individual is accessing or using the Service, as
applicable.</h5>
<h5></h5>
<h5></h5>
<h5></h5>




                    </div>
     
            </section>
        );
    }
}
 
export default Contact;