import React from 'react';
import { Link } from 'react-router-dom';
 
class ServiceOneContent extends React.Component {
    render(){
        return (
            <section id="project" className="project-area uk-project uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project1.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                                <h3><Link to="#">Web Development</Link></h3>
                                <ul>
                                    <li><Link to="#">Web</Link></li>
                                    <li><Link to="#">Mobile</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project2.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                            <h3><Link to="#">Mobile Apps</Link></h3>
                                <ul>
                                    <li><Link to="#">Architect</Link></li>
                                    <li><Link to="#">Systems design</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project3.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                                <h3><Link to="#">Efficient UX/UI Designs</Link></h3>
                                <ul>
                                    <li><Link to="#">Web</Link></li>
                                    <li><Link to="#">Mobile</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project4.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                                <h3><Link to="#">Domain and Hosting Sales</Link></h3>
                                <ul>
                                    <li><Link to="#">Web</Link></li>
                                    <li><Link to="#">Mobile</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project5.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                                <h3><Link to="#">Custom Built Softwares</Link></h3>
                                <ul>
                                    <li><Link to="#">Web</Link></li>
                                    <li><Link to="#">Mobile</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="single-project">
                            <Link to="#" className="project-img">
                                <img src={require("../../assets/images/project6.jpg")} alt="project" />
                            </Link>

                            <div className="project-content">
                            <h3><Link to="/projects">Internet of Things</Link></h3>
                            <ul>
                                <li><Link to="/projects">Web</Link></li>
                                <li><Link to="/projects">Mobile</Link></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ServiceOneContent;