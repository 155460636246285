import React from 'react'
import Banner from '../Home/Banner';
import FeaturesTwo from '../Home/FeaturesTwo';
import SeperatedBar from '../Home/SeperatedBar';
import About from '../Home/About';
import Services from '../Home/Services';
import Project from '../Home/Project';
import PartnerLogo from '../Home/PartnerLogo';
import Footer from '../Common/Footer';
import NavigationFour from '../Navigation/NavigationFour';
 
class Home extends React.Component {
    render(){
        return (
            <>
                <NavigationFour />

                {/* Main Banner */}
                <Banner />

                {/* Features Area */}
                <FeaturesTwo />

                {/* Vertical line */}
                <SeperatedBar />

                {/* About Area */}
                <About />

                {/* Vertical line */}
                <SeperatedBar />

                {/* Services Area */}
                <Services />

                {/* Vertical line */}
                <SeperatedBar />

                {/* Project Area */}
                <Project />

                {/* Vertical line */}
                <SeperatedBar />
                
                {/* Partner Area */}
                <PartnerLogo />

                {/* Vertical line */}
                <SeperatedBar />
                
                {/* Footer Area */}
                <Footer />
            </>
        );
    }
}
 
export default Home;