import React from 'react';
import Footer from '../Common/Footer';
import Banner from '../About/Banner';
import Team from '../Team/TeamBody';
import Banner2 from '../Team/Banner';
import AboutContent from '../About/AboutContent';
import NavigationFour from '../Navigation/NavigationFour';

class About extends React.Component {
    render(){
        return (
            <>

                <NavigationFour />

                <Banner />

                <AboutContent />

                <Banner2 />

                <Team />
                
                <Footer />
            </>
        );
    }
}
 
export default About;