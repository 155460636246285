import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';
import mapImg from '../../assets/images/map.png';


class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            subject: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.subject = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    subjectChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.subject = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div className="alert-success" uk-alert-success="true">
                    <Link to="#" onClick={this.onHideSuccess} className="uk-alert-close" uk-close="true"></Link>
                    <h3>Thank you</h3>
                    <p>We will connect you soon.</p>
                </div>
            );
        }
    }
    render(){
        return (
            <section id="contact" className="contact-area uk-contact uk-section">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>Get in Touch</span>
                        <h2>Contact Us</h2>
                        
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="map-img">
                                <img src={mapImg} alt="map" />

                                <div className="location uk-location1">
                                    <Link to="#" className="active">
                                        <div className="location-info">
                                            <h5>Office Address:</h5>
                                             <span>Apt A4, House 33KA, <br />Road 12/A, Silver Lake Romance,<br />  Dhanmondi R/A, <br /> Dhaka-1209, Bangladesh</span>
                                
                                        </div>
                                    </Link>
                                </div>

                                <div className="location uk-location2">
                                    <Link to="#">
                                        <div className="location-info">
                                            <h5>Dhanmondi Lake</h5>
                                            <span>মসজিদ-উত-তাকওয়া (Masjid-Ut-Taqwa)</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                                <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                                    <div className="item uk-margin">
                                    <div className="single-footer-widget"> </div>
                                    <ul className="contact-info">
                                    <h5>Phone: <a href="tel:+8801847231628">+8801847231628</a></h5>
                                    <h5>Email: <a href="mailto:rnd.itsolutions.limited@gmail.com">rnd.itsolutions.limited@gmail.com</a></h5>                                    
                                    <h5>Address:<a href=" ">Apt A4, House 33KA, <br />Road 12/A, Silver Lake Romance,<br />  Dhanmondi R/A, <br /> Dhaka-1209, Bangladesh</a></h5>
                                </ul>
                                    </div>

                               
                                    </div>


                        </div>
                    </div>
                </div>
           
                
   
                    
            </section>
        );
    }
}
 
export default Contact;