import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';


class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            subject: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.subject = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    subjectChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.subject = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div className="alert-success" uk-alert-success="true">
                    <Link to="#" onClick={this.onHideSuccess} className="uk-alert-close" uk-close="true"></Link>
                    <h3>Thank you</h3>
                    <p>We will connect you soon.</p>
                </div>
            );
        }
    }

    render(){
        return (
            <section id="contact" className="contact-area uk-contact uk-section">
                <div className="uk-container">

<h4>Return &amp; Refund Policy</h4>

<h5>Our Refund Policy was last updated 5 th Decmber 2022</h5>
<h5>Thank you for visiting at https://www.rnditsolutions.com/</h5>
<h5>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on
refunds.</h5>
<h5>The following terms are applicable for any services and products that You purchased with Us.</h5>
<br></br> 
<h4>Interpretation and Definitions</h4>
<h5>Interpretation</h5>                       
<h5>The words of which the initial letter is capitalized have meanings defined under the following conditions.
The following definitions shall have the same meaning regardless of whether they appear in singular or in
plural.</h5>
<br></br> 
<h4>Definitions</h4>
<h5>For the purposes of this Refund Policy:</h5>
<h5>● “Company” (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to
RnD IT Solutions Limited.</h5>
<h5>● “Goods” refers to the items offered for sale on the Service.</h5>
<h5>● “Orders” means a request by You to purchase Goods and Services from Us.</h5>
<h5>● “Service” refers to the Website.</h5>
<h5>● “Website” refers to RnD IT Solutions Limited website, accessible from
https://www.rnditsolutions.com/</h5>
<h5>● “You” means the individual accessing or using the Service, or the company, or other legal entity on
behalf of which such individual is accessing or using the Service, as applicable.</h5>
<br></br> 
<h4>Your Order Cancellation Rights</h4>
<h5>You are not entitled to cancel Your Order by any means. Since we are providing Domain and Hosting
packages.</h5>
<br></br> 
<h4>Conditions for Returns</h4>
<h5>You are not entitled to return Your Order by any means. Since we are providing Domain and Hosting
packages. There is no return policy for Domain and Hosting packages, only revision can be done for
services like website building, etc. which is chargeable.</h5>

<br></br> 
<h4>Contact Us</h4>
<h5>If you have any questions about our Returns and Refunds Policy, please contact us:</h5>
<h5>● By visiting this page on our website: https://www.rnditsolutions.com/</h5>
<h5>● By sending us an email: rnd.itsolutions.limited@gmail.com</h5>

<br></br>
<br></br> 
                    </div>


            
           
                
   
                    
            </section>
        );
    }
}
 
export default Contact;