import React from 'react';
import NavigationFour from '../Navigation/NavigationFour';
import Footer from '../Common/Footer';
import RBanner from '../Return-refund-policy/RBanner';
import Contact from '../Home/refund';
 
class ContactUs extends React.Component {
    render(){
        return (
            <>
                <NavigationFour />

                <RBanner />

                <Contact />
                
                <Footer />
            </>
        );
    }
}
 
export default ContactUs;