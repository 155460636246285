import '../src/assets/css/flaticon.css';
import '../src/assets/css/animate.min.css';
import '../node_modules/uikit/dist/css/uikit.min.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';

import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import HomeSix from './components/Home/Index';
import About from './components/About/About';
import ServicesTwo from './components/Services/ServicesTwo';
import Project from './components/Projects/Project';
import Team from './components/Team/Team';
import Contact from './components/Contact/Contact';
import Terms from './components/terms-of-service/terms';
import Privacy from './components/privacy-policy/privacypolicy';
import Refund from './components/Return-refund-policy/return';

class App extends React.Component {
    render() {
      return (
  <BrowserRouter>
      <Routes>
        <Route  exact path="/" element={<HomeSix />}  />
        <Route  path="/about" element={<About />} />
        <Route  path="/services" element={<ServicesTwo />} />
        <Route  path="/projects" element={<Project />} />
        <Route  path="/payments" />
        <Route  path="/team" element={<Team />} />
        <Route  path="/contact" element={<Contact />} />
        <Route  path="/terms-of-service" element={<Terms />} />
        <Route  path="/privacy-policy" element={<Privacy />} />
        <Route  path="/return-refund-policy" element={<Refund />} />
      </Routes>
  </BrowserRouter>
  );
 }
}
export default App;
 