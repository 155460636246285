import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import aamarpay from '../../assets/images/aamarpay.png';
import footerShapeOne from '../../assets/images/footer-shape1.png';
import footerShapeTwo from '../../assets/images/footer-shape2.png';
import GoTop from './GoTop';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area uk-dark uk-footer">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <p>RnD IT Solutions Limited is an Experienced IT Solutions and Software Development Company that always aims to provide the latest and Next Generation Software and Web Development Solutions.</p>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-footer-widget">
                                <h3>Get in Touch</h3>
                                <div className="bar"></div>

                                <div className="location">
                                    <p>Reach out so we can work together and provide our various IT Solutions services.</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-footer-widget">
                                <h3>Address</h3>
                                <div className="bar"></div>

                                <div className="location">
                                    <p>Apt A4, House 33KA, <br />Road 12/A, Silver Lake Romance,<br />  Dhanmondi R/A, <br /> Dhaka-1209, Bangladesh</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-footer-widget">
                                <h3>Contact</h3>
                                <div className="bar"></div>

                                <ul className="contact-info">
                                    <li><a href="tel:+8801847231628">+8801847231628</a></li>
                                    <li><a href="mailto:rnd.itsolutions.limited@gmail.com">rnd.itsolutions.limited@gmail.com</a></li>                                    
                                </ul>
                                <ul className="social">
                                    <li>
                                        <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank">
                                            <i className="flaticon-logo"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" rel="noopener noreferrer" target="_blank">
                                            <i className="flaticon-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" rel="noopener noreferrer" target="_blank">
                                            <i className="flaticon-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" rel="noopener noreferrer" target="_blank">
                                            <i className="flaticon-logo-1"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <a href="https://payments.rnditsolutions.com"><img src={aamarpay} alt="logo" /></a>
                    <div className="copyright-area">
                        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                            <div className="item">
                                <p>2020-2022 © Copyright RnD IT Solutions Limited. All Rights Reserved</p>
                            </div>

                            <div className="item">
                                <ul>
                                    <li><Link to="/terms-of-service">Terms of services</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <GoTop scrollStepInPx="50" delayInMs="16.66" />
                    </div>
                </div>

                <div className="br-line"></div>
                <div className="footer-shape1">
                    <img src={footerShapeOne} alt="shape" />
                </div>
                <div className="footer-shape2">
                    <img src={footerShapeTwo} alt="shape" />
                </div>
            </footer>
        );
    }
}
 
export default Footer;