import React from 'react';
import { Link } from 'react-router-dom';
 
class TeamBody extends React.Component {
    render(){
        return (
            <section className="team-area uk-team uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="single-team">
                            <ul className="team-social">
                                <li><a href="https://www.facebook.com/yasinabsar1/"><i className="flaticon-logo"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/yasin-absar170391/"><i className="flaticon-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/yabs235/"><i className="flaticon-logo-1"></i></a></li>
                            </ul>

                            <img src={require("../../assets/images/team-1.jpg")} alt="team" />

                            <div className="team-content">
                                <h3>Mr. YASIN ABSAR</h3>
                                <span>Managing Director, Founder and Creative Designer</span>
                            </div>
                        </div>

                        <div className="single-team">
                            <ul className="team-social">
                                <li><Link to="#"><i className="flaticon-logo"></i></Link></li>
                                <li><Link to="#"><i className="flaticon-twitter"></i></Link></li>
                                <li><Link to="#"><i className="flaticon-linkedin"></i></Link></li>
                                <li><Link to="#"><i className="flaticon-logo-1"></i></Link></li>
                            </ul>

                            <img src={require("../../assets/images/team2.jpg")} alt="team" />

                            <div className="team-content">
                                <h3>Mrs. SHARMIN JAHAN</h3>
                                <span>Chairman and Creative Director</span>
                            </div>
                        </div>
                            </div>
                        </div>
            </section>
        );
    }
}
 
export default TeamBody;