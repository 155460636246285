import React from 'react';
import NavigationFour from '../Navigation/NavigationFour';
import Footer from '../Common/Footer';
import PBanner from '../privacy-policy/PBanner';
import Contact from '../Home/privacy';
 
class ContactUs extends React.Component {
    render(){
        return (
            <>
                <NavigationFour />

                <PBanner />

                <Contact />
                
                <Footer />
            </>
        );
    }
}
 
export default ContactUs;