import React from 'react';
import { Link } from 'react-router-dom';
 
class Services extends React.Component {
    render(){
        return (
            <section id="services" className="services-area  uk-services uk-section">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>What We Do</span>
                        <h2>Our Services</h2>
                        <div className="bar"></div>
                        
                        <h4>RnD IT Solutions Limited is always dedicated to providing your IT solution in one go. No matter how many times we need to sit, in order to solve your various IT works, we always try to provide the essential solutions in our first meeting.</h4>

                        <br/>
                        <h4>Our dedicated team are always online to provide the one-stop solutions to every client. We never stop working on every dedicated client with our IT solutions and also provide variations, until our client is totally satisfied.</h4>
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-plan"></i>
                                </div>
                                <h3>Asset Management Systems</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-think"></i>
                                </div>
                                <h3>Creative Solutions</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-shout"></i>
                                </div>
                                <h3>Marketing Solutions</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-analytics-1"></i>
                                </div>
                                <h3>Business Analytics</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-ux-design"></i>
                                </div>
                                <h3>UX & UI Design</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-project"></i>
                                </div>
                                <h3>ERP System</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-project"></i>
                                </div>
                                <h3>Fleet Management System</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-chat"></i>
                                </div>
                                <h3>Social Media</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon">
                                    <i className="flaticon-data"></i>
                                </div>
                                <h3>CRM Systems</h3>

                                <i className="flaticon-right link-btn"></i>

                                <Link to="/services" className="link uk-position-cover"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Services;