import React from 'react';
import NavigationFour from '../Navigation/NavigationFour';
import Footer from '../Common/Footer';
import TBanner from '../terms-of-service/TBanner';
import Contact from '../Home/terms';
 
class ContactUs extends React.Component {
    render(){
        return (
            <>
                <NavigationFour />

                <TBanner />

                <Contact />
                
                <Footer />
            </>
        );
    }
}
 
export default ContactUs;