import React from 'react';
import { Link } from 'react-router-dom';
import 'isomorphic-fetch';

class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            subject: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.subject = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    subjectChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.subject = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div className="alert-success" uk-alert-success="true">
                    <Link to="#" onClick={this.onHideSuccess} className="uk-alert-close" uk-close="true"></Link>
                    <h4>Thank you</h4>
                    <p>We will connect you soon.</p>
                </div>
            );
        }
    }
    render(){
        return (
            <section id="contact" className="contact-area uk-contact uk-section">
                <div className="uk-container">

<h4>Internet Privacy &amp; Cookies Policy</h4>
                        
<h5>This is the internet privacy policy for www.rnditsolutions.com (the “Website”)</h5>
<h5>This website is the property of RnD IT Solutions Limited.  We take the privacy of all visitors
to this Website very seriously and therefore set out in this privacy and cookies policy our
position regarding certain privacy matters and the use of cookies on this Website.</h5>
<h5>We do not store credit card details, nor do we share or sell customer details to any 3rd
parties.</h5>
<h5>This policy covers all data that is shared by a visitor with us whether directly via
www.rnditsolutions.com or via email.</h5>
<h5>This policy provides an explanation as to what happens to any personal data that you share
with us, or that we collect from you either directly via this Website or via email.</h5>
<br></br> 
<h4>1. Information we collect</h4>

<h5>In operating our Website, we may collect and process the following data about you:</h5>
<h5>1.1       Details of your visits to our Website and the resources that you access including, but
not limited to, traffic data, location data, weblog statistics and other communication data.</h5>
<h5>1.2       Information that you provide by filling in forms on our Website, such as when you
register to receive information such as a newsletter or contact us via the contact us page.</h5>
<h5>1.3       Information provided to us when you communicate with us for any reason.</h5>
<br></br> 
<h4>2. Use of cookies</h4>

<h5>On occasion, we may gather information about your computer for our services, and to
provide statistical information regarding the use of our Website.</h5>
<h5>2.1    Such information will not identify you personally, it is statistical data about our visitors
and their use of our site. This statistical data does not identify any personal details whatsoever. It is used by us to analyze how visitors interact with the Website so that we can
continue to develop and improve this Website.</h5>
<h5>2.2    We may gather information about your general Internet use by using a cookie file that
is downloaded to your computer. Where used, these cookies are downloaded to your
computer automatically.  This cookie file is stored on the hard drive of your computer as
cookies contain information that is transferred to your computer’s hard drive. They help us
to improve our Website and the service that we provide to you.</h5>
<h5>2.3    All users can decline cookies through their browser. This can be done by activating
the setting on your browser which enables you to decline the cookies. Please note that
should you choose to decline cookies; you may be unable to access areas of our Website.</h5>
<br></br> 
<h4>3. Use of your information</h4>

<h5>The information that we collect and store relating to you is primarily used to enable us to
provide our services to you. In addition, we may use the information for the following
purposes:</h5>
<h5>3.1       To provide you with information requested from us relating to our products or
services and to provide information on other products which we feel may be of interest to
you if you have consented to receive such information.</h5>
<h5>3.2       To meet our contractual commitments to you.</h5>
<h5>3.3       To notify you about any changes to our Website, such as improvements or
service/product changes, that may affect our service.</h5>
<h5>3.4       If you are an existing customer and have opted into our newsletter, we may contact
you with information about new products that may be of interest to you.</h5>
<h5>3.5      If you are a new customer, we will only contact you when you have provided consent
and only by those means you provided consent for.</h5>
<h5>3.6      We do not share or sell any private information with any outside and unrelated
companies.</h5>
<br></br> 
<h4>4. Storing your personal data</h4>

<h5>4.1       We will take all reasonable steps to make sure that your data is treated securely and
in agreement with this privacy policy.</h5>
<h5>4.2       Data that is provided to us is stored on secure servers operated by RnD IT Servers.
Details relating to any transactions completed on our site will be encrypted to ensure its
safety.</h5>
<h5>4.3       The transmission of information via the internet is not completely secure and
therefore we cannot guarantee the security of data sent to us electronically and the
transmission of such data is entirely at your own risk. Where we have given you (or where
you have chosen) a password so that you can access certain areas of our site, you are
responsible for keeping this password confidential.</h5>
<br></br> 
<h4>5. Credit Card Information</h4>
<h5>We do not store credit card details, nor do we share or sell customer details with any 3rd
parties.</h5>

<br></br> 
<h4>6. Disclosing your information</h4>

<h5>6.1       Your personal information is held securely by RnD IT Solutions Limited.</h5>
<h5>6.2       We may also disclose your personal information to third parties, only where:</h5>
<h5>6.2.1    We sell any or all of our business and/or our assets to a third party.</h5>
<h5>6.2.2    We are legally required to disclose your information.</h5>
<h5>6.2.3    We assist fraud protection and minimize credit risk.</h5>
<br></br> 
<h4>7. Third party links</h4>

<h5>You might find links to third party websites on our Website. These websites should have
their own privacy policies which you should check. We do not accept any responsibility or
liability for their policies whatsoever as we have no control over them.</h5>

<br></br> 
<h4>8.Access to information</h4>

<h5>EU customers only: The Data Protection Act 1998 gives you the right to access the
information that we hold about you. Please note that any demand for access may be subject
to payment of a fee of 1000BDT or $10 which covers our costs in providing you with the
information requested. Should you wish to receive details that we hold about you please
contact us using the contact details below.</h5>

<br></br> 
<h4>9. Contacting us</h4>

<h5>We welcome any queries, comments or requests you may have regarding this policy please
do not hesitate to contact us at rnd.itsolutions.limited@gmail.com.
If you would prefer to write to us then our contact address is: RnD IT Solutions Limited,
Apt A4, House 33KA, Road 12/A, Silver Lake Romance, Dhanmondi R/A, Dhaka-1209,
Bangladesh.</h5>
<br></br> 
<br></br> 

                    </div>
           
            </section>
        );
    }
}
 
export default Contact;