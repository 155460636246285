import React from 'react';
 
class Banner extends React.Component {
    render(){
        return (
            <section className="page-title-area uk-page-title">
                <div className="uk-container">
                    <h1>Board of Directors</h1>
                </div>
            </section>
        );
    }
}
 
export default Banner;