import React from 'react';
import { Link } from 'react-router-dom';
class NavigationFour extends React.Component {
    render(){
        let pathName = window.location.pathname;
        return (
            <React.Fragment>
                {/* Start Mobile Navbar */}
                <div id="offcanvas-flip" className="mobile-navbar" uk-offcanvas="flip: false; overlay: true">
                    <div className="uk-offcanvas-bar">
                
                        <button className="uk-offcanvas-close" type="button" uk-close="true"></button>

                        <nav className="uk-navbar-container">
                        <ul className="uk-navbar-nav">                            
                        <li className={ pathName === '/' ? 'uk-active' : '' } ><Link to="/">Home</Link></li>
                            <li className={ pathName === '/about' ? 'uk-active' : '' } ><Link to="/about">About</Link></li>
                            <li className={ pathName === '/services' ? 'uk-active' : '' } ><Link to="/services">Services</Link></li>
                            <li className={ pathName === '/projects' ? 'uk-active' : '' } ><Link to="/projects">Projects</Link></li>
                            <li><Link to="#">Payments</Link>
                                    <div uk-dropdown="mode: click">
                                        <ul className="uk-nav uk-dropdown-nav">
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                            <li><Link to="/return-refund-policy">Return &amp; Refund Policy</Link></li>
                                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                            <li><a href="https://payments.rnditsolutions.com">Continue to RnD IT Payments</a></li>
                                        </ul>
                                    </div>
                                </li>
                            <li className={ pathName === '/contact' ? 'uk-active' : '' } ><Link to="/contact">Contact</Link></li>
                        </ul>
                        </nav>
                    </div>
                </div>
                {/* End Mobile Navbar */}

                {/* Start Navbar Area */}
                <header className="header-area uk-dark" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
                    <div className="uk-container">
                        <div className="uk-navbar">
                            <div className="logo uk-navbar-left">
                                <a href="/">
                                    <img src={require("../../assets/images/rnd.png")} alt="logo" />
                                </a>
                            </div>

                            <div className="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div className="navbar uk-navbar-right">
                                <nav className="uk-navbar-container">
                        <ul className="uk-navbar-nav">                            
                            <li className={ pathName === '/' ? 'uk-active' : '' } ><Link to="/">Home</Link></li>
                            <li className={ pathName === '/about' ? 'uk-active' : '' } ><Link to="/about">About</Link></li>
                            <li className={ pathName === '/services' ? 'uk-active' : '' } ><Link to="/services">Services</Link></li>
                            <li className={ pathName === '/projects' ? 'uk-active' : '' } ><Link to="/projects">Projects</Link></li>
                            <li><Link to="#">Payments</Link>
                                    <div uk-dropdown="mode: click">
                                        <ul className="uk-nav uk-dropdown-nav">
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                            <li><Link to="/return-refund-policy">Return &amp; Refund Policy</Link></li>
                                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                            <li><a href="https://payments.rnditsolutions.com">Continue to RnD IT Payments</a></li>
                                        </ul>
                                    </div>
                                </li>
                            <li className={ pathName === '/contact' ? 'uk-active' : '' } ><Link to="/contact">Contact</Link></li>
                        </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}
 
export default NavigationFour;